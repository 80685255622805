@import "~antd-mobile/lib/style/themes/default.less";

// Theme colors
// @brand-primary: #108ee9;
@brand-primary: #1b7a47;
@brand-primary-tap: #145a35;
@brand-success: #6abf47;
@brand-success-light: #6abf4744;
@brand-warning: #ffc600;
@brand-error: #f4333c;
@brand-important: #208b52;
@brand-wait: #108ee9;

@color-primary: #108ee9;
@process-icon-color: @color-primary;
@finish-tail-color: @color-primary;

.am-checkbox.am-checkbox-checked .am-checkbox-inner {
	background-color: @color-primary;
	border-color: @color-primary;
}

.am-list-thumb span.anticon {
	font-size: 1.6em;
	color: @color-primary;
}

.am-list-item.primary {
	.am-list-content {
		text-align: center;
	}

	&:not(.am-list-item-disabled) {
		.am-icon,
		.anticon,
		.am-list-content {
			color: @brand-primary;
		}
	}
}

.am-list-item.danger {
	&:not(.am-list-item-disabled) {
		.am-icon,
		.anticon,
		.am-list-content {
			color: @brand-error;
		}
	}
}

.am-list-item-disabled .am-list-thumb {
	.am-icon,
	.anticon {
		color: @color-text-disabled;
	}
}

.am-popover-item-icon {
	span.anticon {
		font-size: @icon-size-xs;
	}
}

// .am-steps-item-finish .am-steps-item-icon > .am-steps-icon {
// 	color: @color-primary;
// }

// .am-steps-item-process .am-steps-item-icon {
// 	background: @color-primary;
// }

.am-steps-item-title {
	font-weight: normal;
}

.am-steps-item-process {
	.am-steps-item-icon {
		border-color: @brand-primary;
	}
	.am-steps-item-title {
		font-weight: 500;
	}
}

.am-steps-item-wait {
	.am-steps-item-title {
		color: @color-text-secondary;
	}
}

.am-steps-item-finish {
	.am-steps-item-tail::after {
		background: @brand-primary;
	}
}

.list-header-button {
	float: right;
	margin: -15px -5px -15px 0;
	height: 40px;
	color: black;
}

span.text-danger {
	color: @brand-error;
}
